export const questions = {
  workday: {
    text: "How many hours do you typically work?",
    options: [
      { label: "6-8 hours", value: 7 },
      { label: "8-10 hours", value: 9 },
      { label: "10-12 hours", value: 11 },
    ],
  },
  focus: {
    text: "In your typical workday, how many hours are truly productive?",
    options: [
      { label: "1-2 hours", value: 1.5 },
      { label: "2-4 hours", value: 3 },
      { label: "4-6 hours", value: 5 },
    ],
  },
  currency: {
    text: "Select your currency:",
    options: [
      { label: "USD (US Dollar)", value: ["USD", "$", 12000] }, // $144K/year
      { label: "INR (Indian Rupee)", value: ["INR", "₹", 150000] }, // ₹18L/year
      { label: "EUR (Euro)", value: ["EUR", "€", 5500] }, // €66K/year
      { label: "GBP (British Pound)", value: ["GBP", "£", 5000] }, // £60K/year
    ],
  },
  income: {
    text: "What's your monthly income?",
    type: "number",
  },
  challenge: {
    text: "What's your biggest daily challenge?",
    options: [
      {
        label: "Too many decisions to make",
        value: [
          "decision fatigue",
          "clear decision rules and daily policies, eliminating decision fatigue",
        ],
      },
      {
        label: "Can't maintain focus for long",
        value: [
          "focus",
          "proven flow state techniques that can make you 5x more productive",
        ],
      },
      {
        label: "Poor energy management",
        value: [
          "energy",
          "energy-aligned scheduling that matches your peak performance times with important work",
        ],
      },
    ],
  },
};
