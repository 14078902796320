import React from "react";
import { Box, Typography, Tooltip, Paper, Grid } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import AssessmentIcon from "@mui/icons-material/Assessment";

const AttentionScoreGrid = ({ attentionScores = {} }) => {
  const bellPositions = [5, 4, 3, 2, 1];
  const totalDays = 21;

  // Convert attentionScores object into an array of completed days
  const completedDays = Object.entries(attentionScores)
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .slice(0, totalDays);

  const MotionBox = motion(Box);

  // Helper function to get bell-specific attention status
  const getBellAttentionStatus = (dayIndex, bellNumber) => {
    if (dayIndex >= completedDays.length) return false;
    const dayScores = completedDays[dayIndex][1];
    return dayScores[bellNumber - 1] || false;
  };

  return (
    <Paper
      elevation={3}
      sx={{ mt: 4, p: 3, borderRadius: 4, bgcolor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AssessmentIcon sx={{ mr: 1, color: "primary.main" }} />
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Attention Score Grid
          </Typography>
        </Box>
        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
          Track your daily focus levels
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {Array.from({ length: totalDays }, (_, dayIndex) => (
          <Grid item xs={12 / 21} key={dayIndex}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {bellPositions.map((bell) => {
                const isAttentive = getBellAttentionStatus(dayIndex, bell);
                const completedDate = completedDays[dayIndex]?.[0] || null;

                return (
                  <Tooltip
                    key={`day${dayIndex + 1}-${bell}`}
                    title={
                      <Box sx={{ p: 1 }}>
                        <Typography variant="body2">
                          Day {dayIndex + 1}
                        </Typography>
                        {completedDate && (
                          <Typography variant="caption" display="block">
                            Completed on:{" "}
                            {new Date(
                              new Date(completedDate).getTime() +
                                new Date().getTimezoneOffset() * 60000
                            ).toLocaleDateString()}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          Bell {bell}:{" "}
                          {isAttentive ? "Attentive" : "Not Attentive"}
                        </Typography>
                      </Box>
                    }
                    arrow
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "100%",
                        mb: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          borderRadius: "50%",
                          bgcolor: "#e0e0e0",
                        }}
                      />
                      <AnimatePresence>
                        {isAttentive && (
                          <MotionBox
                            initial={{ y: -75 }}
                            animate={{ y: 0 }}
                            transition={{
                              type: "spring",
                              damping: 15,
                              mass: 1.2,
                              stiffness: 50,
                              delay: dayIndex * 0.3,
                            }}
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              borderRadius: "50%",
                              bgcolor: "#4A148C",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            }}
                          />
                        )}
                      </AnimatePresence>
                    </Box>
                  </Tooltip>
                );
              })}
              <Typography
                variant="caption"
                sx={{
                  textAlign: "center",
                  fontWeight: 500,
                  color: "text.secondary",
                  mt: 0.5,
                }}
              >
                {dayIndex + 1}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default AttentionScoreGrid;
