import React, { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Box } from "@mui/material";
import { useUser } from "../contexts/userContext";

const stripePromise = loadStripe(
  "pk_test_51QAZkXErGxZ7zIOYGCFoPtvTXGbkMM0cPX2VYUhBO4OX9i8cRfhe9Z6dtIX7RiEGCfkMgTbhFNs0bGQJ9u5JkaND00Y9GyAxWQ"
);

const CheckoutForm = () => {
  const { user } = useUser();
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Get promo code from URL if exists
  const searchParams = new URLSearchParams(window.location.search);
  const promoCode = searchParams.get("promo");

  const fetchClientSecret = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://backend.ekrasworks.com/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user?.id,
            promoCode: promoCode, // Pass promo code if exists
          }),
        }
      );
      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.id, promoCode]);

  useEffect(() => {
    if (user?.id) fetchClientSecret();
  }, [user?.id, fetchClientSecret]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        Loading checkout...
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 2 }}>
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </Box>
  );
};

export default CheckoutForm;
