import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";

const Stripe = () => {
  const stripePromise = loadStripe(
    "pk_test_51QAZkXErGxZ7zIOYGCFoPtvTXGbkMM0cPX2VYUhBO4OX9i8cRfhe9Z6dtIX7RiEGCfkMgTbhFNs0bGQJ9u5JkaND00Y9GyAxWQ"
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default Stripe;
