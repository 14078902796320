import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useLocation, useNavigate } from "react-router-dom";

const StreakCalendar = ({ completedDates = [], startDate }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const start = dayjs(startDate);
  const today = dayjs();
  const calendarRef = useRef(null);
  const todayRef = useRef(null);

  const lastCompletedDate =
    completedDates.length > 0
      ? dayjs(Math.max(...completedDates.map((date) => dayjs(date).valueOf())))
      : null;
  const endDate =
    lastCompletedDate && lastCompletedDate.isAfter(today)
      ? lastCompletedDate
      : today;

  const totalDays = Math.max(21, endDate.diff(start, "day") + 1);
  const daysArray = Array.from({ length: totalDays }, (_, i) =>
    start.add(i, "day")
  );

  useEffect(() => {
    if (todayRef.current && calendarRef.current) {
      const scrollContainer = calendarRef.current;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, []);

  // Rest of the existing functions remain the same
  const getActiveDayNumber = (date) => {
    const sortedDates = [...completedDates].sort(
      (a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()
    );

    const dayIndex = sortedDates.findIndex(
      (d) => dayjs(d).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
    );

    return dayIndex + 1;
  };

  const handleDayClick = (dayNumber) => {
    const params = new URLSearchParams(location.search);
    params.set("day", dayNumber.toString());
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ mr: 1, color: "primary.main" }} />
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Streak Calendar
          </Typography>
        </Box>
        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
          Track your 21-day learning journey
        </Typography>
      </Box>
      <Box
        ref={calendarRef}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: 1,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          maxHeight: "400px",
          overflowY: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
            "&:hover": {
              background: "#555",
            },
          },
        }}
      >
        {/* Rest of the JSX remains the same */}
        {daysArray.map((date, index) => {
          const isCompleted = completedDates.some(
            (completedDate) =>
              dayjs(completedDate).format("YYYY-MM-DD") ===
              date.format("YYYY-MM-DD")
          );
          const isPastDate = date.isBefore(today, "day");
          const isToday = date.isSame(today, "day");
          const isWithin21Days = index < 21;
          const activeDay = isCompleted ? getActiveDayNumber(date) : null;
          const currentUrlDay = new URLSearchParams(location.search).get("day");
          const isSelected = activeDay === parseInt(currentUrlDay);
          const isClickable =
            isCompleted || index === completedDates.length || isToday;

          return (
            <Box
              key={date.format("YYYY-MM-DD")}
              ref={isToday ? todayRef : null}
              onClick={() =>
                isClickable &&
                handleDayClick(activeDay || completedDates.length + 1)
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 1,
                borderRadius: 1,
                bgcolor: isSelected
                  ? "rgba(63, 81, 181, 0.08)"
                  : isToday
                  ? "rgba(255, 255, 255, 1)"
                  : isWithin21Days
                  ? "rgba(0,0,0,0.02)"
                  : "rgba(0,0,0,0.01)",
                position: "relative",
                border: isToday
                  ? "2px dashed rgba(63, 81, 181, 0.5)"
                  : isSelected
                  ? "1px solid rgba(63, 81, 181, 0.5)"
                  : "none",
                opacity: isWithin21Days ? 1 : 0.7,
                cursor: isClickable ? "pointer" : "default",
                "&:hover": isClickable
                  ? {
                      bgcolor: "rgba(63, 81, 181, 0.12)",
                      opacity: 0.9,
                    }
                  : {},
                transition: "all 0.2s ease-in-out",
                boxShadow: isToday
                  ? "0 0 0 1px rgba(63, 81, 181, 0.2)"
                  : "none",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  mb: 0.5,
                  color: isSelected
                    ? "primary.main"
                    : isToday
                    ? "primary.dark"
                    : "text.secondary",
                  fontWeight: isSelected || isToday ? 600 : 400,
                  fontSize: "16px",
                }}
              >
                {date.format("DD")}
              </Typography>
              {isPastDate || isToday ? (
                isCompleted ? (
                  <CheckCircleIcon
                    sx={{
                      color: "success.main",
                      fontSize: "1.5rem",
                      opacity: isWithin21Days ? 0.8 : 0.5,
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "error.main",
                      fontSize: "1.5rem",
                      opacity: isWithin21Days ? 0.3 : 0.2,
                    }}
                  />
                )
              ) : (
                <Box
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                    borderRadius: "50%",
                    border: "1px dashed rgba(0,0,0,0.2)",
                    opacity: isWithin21Days ? 1 : 0.5,
                  }}
                />
              )}
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  color: isSelected
                    ? "primary.dark"
                    : isToday
                    ? "primary.main"
                    : "text.secondary",
                  fontWeight: isSelected || isToday ? 600 : 400,
                  fontSize: "16px",
                }}
              >
                {date.format("MMM")}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default StreakCalendar;
