import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useUser } from "../contexts/userContext";
import { supabase } from "../utils/supabase";
import { getUserByPhone } from "../controllers/users";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddIcon from "@mui/icons-material/Add";
import S3Image from "../components/S3Image";

const Assessments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAssessmentsLoading, setIsAssessmentsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assessments, setAssessments] = useState(null);

  const location = useLocation();
  const { user, setUser } = useUser();

  useEffect(() => {
    const verifyAndFetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const params = queryString.parse(location.search);
        const { id } = params;

        if (id) {
          const columnName = "preferences_link";
          const { data, error } = await supabase
            .from("users_onboard")
            .select(`id, whatsapp_phone_no, ${columnName}`);

          if (error) throw error;

          if (data?.length > 0) {
            const row = data.find(
              (row) => row[columnName] && row[columnName][params.id]
            );
            if (row) {
              const userData = await getUserByPhone(row[columnName][params.id]);
              setUser(userData);
              localStorage.setItem("user", JSON.stringify(userData));
            } else {
              setError("Invalid verification link");
            }
          }
        }
      } catch (err) {
        setError("Failed to verify user");
      } finally {
        setIsLoading(false);
      }
    };

    verifyAndFetchData();
  }, [location, setUser]);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        setIsAssessmentsLoading(true);
        if (!user?.id) return;

        const { data, error } = await supabase
          .from("ffin_assessments")
          .select("*")
          .eq("user_id", user.id)
          .order("assessment_date", { ascending: false });

        if (error) throw error;
        setAssessments(data || []);
      } catch (err) {
        console.error("Error fetching assessments:", err);
        setError("Failed to load assessments");
      } finally {
        setIsAssessmentsLoading(false);
      }
    };

    fetchAssessments();
  }, [user]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: "0 auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4" sx={{ display: "flex", alignItems: "center" }}>
          <AssessmentIcon sx={{ mr: 1, color: "primary.main" }} />
          Your Assessments
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          href="https://api.whatsapp.com/send/?phone=19259998038&text=Start%20Assessment"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ borderRadius: 2 }}
        >
          Take New Assessment
        </Button>
      </Box>

      {isAssessmentsLoading || !assessments ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="40vh"
        >
          <CircularProgress />
        </Box>
      ) : assessments.length === 0 ? (
        <Card sx={{ textAlign: "center", py: 6, bgcolor: "grey.50" }}>
          <CardContent>
            <Typography variant="h6" color="text.secondary">
              No assessments taken yet
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Start your first assessment to track your progress
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Grid container spacing={3}>
          {assessments.map((assessment) => (
            <Grid item xs={12} md={6} key={assessment.id}>
              <Card sx={{ height: "100%", borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Assessment{" "}
                    {new Date(assessment.assessment_date).toLocaleDateString()}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    FFIN Code: {assessment.ffin_code}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <S3Image
                          assessmentId={assessment.id}
                          imageType="assessment"
                          alt="Assessment Result"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: 8,
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            pointerEvents: "none",
                            WebkitTouchCallout: "none",
                            MozUserSelect: "none",
                            msUserSelect: "none",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <S3Image
                          assessmentId={assessment.id}
                          imageType="recommendation"
                          alt="Recommendations"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: 8,
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            pointerEvents: "none",
                            WebkitTouchCallout: "none",
                            MozUserSelect: "none",
                            msUserSelect: "none",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Assessments;
