import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const S3Image = ({ assessmentId, imageType, alt, style }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 200,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <img
        src={`https://backend.ekrasworks.com/api/image-proxy/${assessmentId}?type=${imageType}`}
        alt={alt}
        style={style}
        onLoad={() => setIsLoading(false)}
        onError={(e) => {
          setError("Failed to load image");
          setIsLoading(false);
        }}
        onContextMenu={(e) => e.preventDefault()}
        draggable="false"
      />
      {error && !isLoading && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
    </>
  );
};

export default S3Image;
