import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supabase";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const UserReferralManager = () => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPercentage, setNewPercentage] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchReferrals();
  }, []);

  const fetchReferrals = async () => {
    const { data, error } = await supabase
      .from("user_referral_codes")
      .select(
        `
            *,
            users_onboard!user_onboard_id (
                name,
                email
            )
        `
      )
      .order("created_at", { ascending: false });

    if (data) {
      // Process the data to flatten the nested structure
      const processedData = data.map((item) => ({
        ...item,
        name: item.users_onboard?.name || "-",
        email: item.users_onboard?.email || "-",
      }));
      setReferrals(processedData);
    }
  };

  console.log("referrals: ", referrals);

  const handleUpdatePercentage = async () => {
    setLoading(true);
    try {
      const { error } = await supabase
        .from("user_referral_codes")
        .update({ discount_percentage: parseFloat(newPercentage) })
        .neq("id", 0); // This will update all rows

      if (error) throw error;
      setAlert({
        open: true,
        message: "Discount percentage updated successfully!",
        severity: "success",
      });
      fetchReferrals();
    } catch (error) {
      setAlert({ open: true, message: error.message, severity: "error" });
    } finally {
      setLoading(false);
      setNewPercentage("");
    }
  };

  const columns = [
    { field: "code", headerName: "Referral Code", width: 150 },
    { field: "name", headerName: "User Name", width: 200 },
    { field: "email", headerName: "User Email", width: 200 },
    { field: "discount_percentage", headerName: "Discount %", width: 130 },
    { field: "current_uses", headerName: "Uses", width: 100 },
    {
      field: "valid_until",
      headerName: "Valid Until",
      width: 200,
      valueGetter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <Typography color={params.value ? "success.main" : "error.main"}>
          {params.value ? "Active" : "Inactive"}
        </Typography>
      ),
    },
  ];

  return (
    <Box>
      <Paper elevation={3} sx={{ p: 3, mb: 4, mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Update All User Referral Discounts
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="New Discount Percentage"
            type="number"
            value={newPercentage}
            onChange={(e) => setNewPercentage(e.target.value)}
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
          <Button
            variant="contained"
            onClick={handleUpdatePercentage}
            disabled={loading || !newPercentage}
          >
            Update All
          </Button>
        </Box>
      </Paper>

      <Typography variant="h5" gutterBottom>
        User Referrals
      </Typography>
      <DataGrid
        rows={referrals}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserReferralManager;
