import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useUser } from "../contexts/userContext";
import { supabase } from "../utils/supabase";
import { getUserByPhone } from "../controllers/users";
import Main from "./Main";
import { CircularProgress, Alert, Box, Typography } from "@mui/material";
import ShareableViewInterim from "./ShareableViewInterim";
import { styled } from "@mui/material/styles";

const Home = () => {
  const [courseId, setCourseId] = useState(null);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState("pending");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [challengeData, setChallengeData] = useState(true);

  const location = useLocation();
  const { user, setUser } = useUser();

  const getDeviceInfo = () => {
    return {
      browser: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenSize: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
    };
  };

  const getDeviceId = () => {
    let deviceId = document.cookie.match(/deviceId=([^;]+)/);
    if (!deviceId) {
      deviceId = crypto.randomUUID();
      document.cookie = `deviceId=${deviceId};path=/;`;
    } else {
      deviceId = deviceId[1];
    }
    return deviceId;
  };

  const checkSession = async (userId, isRedirect) => {
    if (!userId || checkSession.isCreatingSession) return;

    try {
      checkSession.isCreatingSession = true;

      const { data: existingSessions, error } = await supabase
        .from("user_sessions")
        .select("*")
        .eq("user_id", userId);

      if (error) throw error;

      const deviceId = isRedirect ? isRedirect : getDeviceId();
      const matchingSession = existingSessions?.find(
        (session) =>
          session.device_info.deviceId === deviceId &&
          session.is_active === true
      );

      if (matchingSession) {
        setSessionDetails(matchingSession);
        localStorage.setItem("isSessionActive", true);
      } else {
        await handleNewSessionVerification(userId);
      }
    } catch (error) {
      console.error("Error managing session:", error);
      setError("Error managing session. Please try again.");
    } finally {
      checkSession.isCreatingSession = false;
    }
  };

  const handleNewSessionVerification = async (userId) => {
    try {
      setError(null);
      const verificationId = crypto.randomUUID();
      let timeoutId;

      await supabase.from("session_verifications").insert([
        {
          id: verificationId,
          user_id: userId,
          status: "pending",
          device_id: getDeviceId(),
          created_at: new Date().toISOString(),
        },
      ]);

      document.addEventListener("visibilitychange", async () => {
        if (!document.hidden) {
          const { data } = await supabase
            .from("session_verifications")
            .select("status")
            .eq("id", verificationId)
            .single();

          if (data) {
            handleStatusChange(data.status);
          }
        }
      });

      const subscription = supabase
        .channel("session_verifications")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "session_verifications",
            filter: `id=eq.${verificationId}`,
          },
          (payload) => handleStatusChange(payload.new.status)
        )
        .subscribe();

      const handleStatusChange = async (newStatus) => {
        setVerificationStatus(newStatus);

        if (newStatus === "approved_remember") {
          await createNewSession(userId);
          cleanup();
        } else if (newStatus === "approved_no_remember") {
          setSessionDetails({
            id: "temporary",
            user_id: userId,
            device_info: getDeviceInfo(),
            start_time: new Date().toISOString(),
            is_active: true,
            is_temporary: true,
          });
          cleanup();
        } else if (newStatus === "forget_all") {
          try {
            const { error } = await supabase
              .from("user_sessions")
              .delete()
              .eq("user_id", userId);

            if (error) throw error;
            setSessionDetails(null);
            localStorage.removeItem("isSessionActive");
            setError("All sessions cleared and current request rejected");
          } catch (error) {
            setError("Failed to clear sessions");
          }
          cleanup();
        } else if (newStatus === "rejected") {
          setError("Session was rejected by user");
          cleanup();
        } else if (newStatus === "expired") {
          setError("Verification timeout: Please try again");
          cleanup();
        }
      };

      const cleanup = () => {
        subscription.unsubscribe();
        clearTimeout(timeoutId);
      };

      const checkExpiration = async () => {
        const now = new Date();
        const expirationTime = new Date(now.getTime() + 5 * 60 * 1000);

        timeoutId = setInterval(async () => {
          if (
            new Date() >= expirationTime &&
            verificationStatus === "pending"
          ) {
            await supabase
              .from("session_verifications")
              .update({ status: "expired" })
              .eq("id", verificationId);

            cleanup();
          }
        }, 30000);
      };

      checkExpiration();
    } catch (error) {
      setError("Verification failed: Please try again");
    }
  };

  const createNewSession = async (userId) => {
    const deviceInfo = {
      ...getDeviceInfo(),
      deviceId: getDeviceId(),
    };

    try {
      const { data, error } = await supabase
        .from("user_sessions")
        .insert([
          {
            user_id: userId,
            device_info: deviceInfo,
            start_time: new Date().toISOString(),
            is_active: true,
          },
        ])
        .select();

      if (error) throw error;

      setSessionDetails(data[0]);
      localStorage.setItem("isSessionActive", true);
    } catch (error) {
      setError("Failed to create new session");
    }
  };

  useEffect(() => {
    const verifyAndFetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const params = queryString.parse(location.search);
        const { id, course_id } = params;

        if (id) {
          const columnName = "preferences_link";
          const { data, error } = await supabase
            .from("users_onboard")
            .select(`id, whatsapp_phone_no, ${columnName}`);

          if (error) throw error;

          if (data?.length > 0) {
            const row = data.find(
              (row) => row[columnName] && row[columnName][params.id]
            );
            if (row) {
              const userData = await getUserByPhone(row[columnName][params.id]);
              setUser(userData);
              localStorage.setItem("user", JSON.stringify(userData));
              const { data: challenge } = await supabase
                .from("challenges")
                .select("*")
                .eq("user_id", userData.id)
                .eq("course_id", course_id)
                .single();

              if (challenge) setChallengeData(challenge);
              await checkSession(userData.id, params.redirect);
            } else {
              setError("Invalid verification link");
            }
          }
        }
        if (course_id) setCourseId(course_id);
      } catch (err) {
        setError("Failed to verify user");
      } finally {
        setIsLoading(false);
      }
    };

    verifyAndFetchData();
  }, [location, setUser]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <CircularProgress size={40} />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Verifying your session...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        p={2}
      >
        <Alert severity="error" sx={{ maxWidth: 400 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  // Main verification component
  if (verificationStatus === "pending" && !sessionDetails) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: { xs: "flex-start", md: "center" },
          pt: { xs: 4, md: 0 },
          boxSizing: "border-box",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", md: "800px" },
            px: { xs: 2, sm: 3 },
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress size={40} />
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              textAlign: "center",
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            Waiting for session verification... Please check your WhatsApp for
            approval
          </Typography>
          {user && challengeData && (
            <Box
              sx={{
                width: "100%",
                mt: 3,
                boxSizing: "border-box",
              }}
            >
              <ShareableViewInterim
                userId={user.id}
                challengeId={challengeData.id}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <div>
      {sessionDetails && (
        <Main
          user={user}
          courseId={courseId}
          initialDay={
            queryString.parse(location.search).day === undefined ||
            queryString.parse(location.search).day === null
              ? 1
              : parseInt(queryString.parse(location.search).day)
          }
          sessionDetails={sessionDetails}
        />
      )}
    </div>
  );
};

export default Home;
