import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import the layouts
import Home from "./layouts/Home";
import Stripe from "./layouts/Stripe";
import Return from "./layouts/Return";
import CourseList from "./layouts/CourseList";
import UserRegistrationPage from "./layouts/UserRegistrationPage";
import UserLogin from "./layouts/UserLogin";
import DiscountManager from "./layouts/DiscountManager";
import ShareableView from "./layouts/ShareableView";
import Gist from "./layouts/Gist";
import Landing from "./layouts/Landing";
import Assessments from "./layouts/Assessments";
import TrainMan from "./layouts/TrainMan";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/challenge" element={<Home />} />
        <Route path="/assessments" element={<Assessments />} />
        <Route path="/trainman" element={<TrainMan />} />
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/return" element={<Return />} />
        <Route path="/courses" element={<CourseList />} />
        <Route path="/register" element={<UserRegistrationPage />} />
        <Route path="/signin" element={<UserLogin />} />
        <Route path="/share/:shareId" element={<ShareableView />} />
        <Route path="/gist" element={<Gist />} />
        <Route path="/add-coupon" element={<DiscountManager />} />
      </Routes>
    </Router>
  );
};

export default App;
