import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Link,
  Alert,
  CircularProgress,
} from "@mui/material";
import { supabase } from "../utils/supabase";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkLocalStorage = async () => {
      try {
        const storedUser = localStorage.getItem("user");
        const isSessionActive = localStorage.getItem("isSessionActive");

        if (storedUser && isSessionActive) {
          const userData = JSON.parse(storedUser);

          // Get challenge data for the user
          const { data: challengeData, error: challengeError } = await supabase
            .from("challenges")
            .select(
              `
              *,
              daily_progress (
                date,
                is_completed
              )
            `
            )
            .eq("user_id", userData.id)
            .single();

          if (challengeError) throw challengeError;

          const completedDays =
            challengeData.daily_progress?.filter((p) => p.is_completed)
              .length || 0;
          const sessionDay = Math.min(completedDays + 1, 21);

          if (userData.preferences_link) {
            const preferenceKeys = Object.keys(userData.preferences_link);
            window.location.href = `https://challenge.ekrasworks.com/challenge?id=${preferenceKeys[0]}&course_id=1&day=${sessionDay}`;
            return;
          }
        }
        setIsLoading(false);
      } catch (err) {
        console.error("Error checking local storage:", err);
        localStorage.removeItem("user");
        localStorage.removeItem("isSessionActive");
        setIsLoading(false);
      }
    };

    checkLocalStorage();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: userData, error: userError } = await supabase
        .from("users_onboard")
        .select("*")
        .ilike("email", email)
        .single();

      if (userError) throw userError;

      if (!userData?.preferences_link) {
        setError("No preferences found for this user");
        return;
      }

      const { data: challengeData, error: challengeError } = await supabase
        .from("challenges")
        .select(
          `
          *,
          daily_progress (
            date,
            is_completed
          )
        `
        )
        .eq("user_id", userData.id)
        .single();

      if (challengeError) throw challengeError;

      const completedDays =
        challengeData.daily_progress?.filter((p) => p.is_completed).length || 0;
      const sessionDay = Math.min(completedDays + 1, 21);

      const preferenceKeys = Object.keys(userData.preferences_link);
      window.location.href = `https://challenge.ekrasworks.com/challenge?id=${preferenceKeys[0]}&course_id=1&day=${sessionDay}`;
    } catch (err) {
      setError("Invalid email or user not found");
      console.error("Error:", err);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        p={2}
      >
        <Alert severity="error" sx={{ maxWidth: 400 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 2, sm: 4 },
          marginTop: 4,
          maxWidth: "100%",
          mx: "auto",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              mb: 4,
              fontWeight: 500,
            }}
          >
            Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              maxWidth: 400,
              mx: "auto",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                mb: 3,
                py: 1.5,
              }}
            >
              Sign In
            </Button>
            <Box sx={{ mt: 2 }}>
              <Link
                href="/register"
                variant="body2"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Don't have an account? Sign up
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default UserLogin;
