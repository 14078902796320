import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PDFViewer = ({ pdfFile }) => {
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [pageHeight, setPageHeight] = useState(0);

  useEffect(() => {
    let isCancelled = false; // Flag to prevent duplicate rendering

    const loadPDF = async () => {
      setLoading(true);
      try {
        const loadingTask = getDocument(pdfFile);
        const pdf = await loadingTask.promise;

        if (isCancelled) return; // Skip rendering if the effect is cancelled

        const container = containerRef.current;
        container.innerHTML = ""; // Clear container before rendering

        // Render the first page to determine the page height
        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 1 });

        const containerWidth = container.offsetWidth;
        const scale = containerWidth / viewport.width;
        const scaledViewport = firstPage.getViewport({ scale });

        setPageHeight(scaledViewport.height);

        // Render all pages
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          if (isCancelled) return; // Stop rendering if the effect is cancelled

          const page = await pdf.getPage(pageNum);
          const adjustedViewport = page.getViewport({ scale });

          // Create canvas
          const canvas = document.createElement("canvas");
          canvas.width = adjustedViewport.width;
          canvas.height = adjustedViewport.height;

          const context = canvas.getContext("2d");
          const renderContext = {
            canvasContext: context,
            viewport: adjustedViewport,
          };

          await page.render(renderContext).promise;
          container.appendChild(canvas);
        }
      } catch (error) {
        console.error("Error loading PDF:", error);
      } finally {
        if (!isCancelled) setLoading(false); // Stop loader if not cancelled
      }
    };

    loadPDF();

    // Cleanup to prevent duplicate rendering
    return () => {
      isCancelled = true;
    };
  }, [pdfFile]);

  return (
    <Box
      sx={{
        width: "100%",
        height: pageHeight || "100%",
        overflowY: "auto",
        WebkitOverflowScrolling: "touch",
        position: "relative",
        backgroundColor: "#f9f9f9",
        mb: 2,
      }}
    >
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box ref={containerRef} sx={{ width: "100%" }} />
    </Box>
  );
};

export default PDFViewer;
