import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getUserByPhone } from "../controllers/users";
import { useUser } from "../contexts/userContext";
import { supabase } from "../utils/supabase";

// Custom theme with updated typography
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f0f0f0",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
    },
  },
});

// Enhanced Course Component with new styles
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: theme.shadows[4],
  },
  backgroundColor: theme.palette.background.paper,
}));

const CourseIcon = styled("div")({
  fontSize: "2.5rem",
  marginBottom: theme.spacing(2),
});

const courses = [
  {
    id: 1,
    title: "Focus and Finish 21 Day Challenge",
    price: 297.99,
    icon: "🎯",
  },
  { id: 2, title: "Productivity Masterclass", price: 249.99, icon: "⏱️" },
  { id: 3, title: "Mindfulness for Beginners", price: 149.99, icon: "🧘" },
];

const CourseList = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { user, setUser } = useUser();

  const verifyUser = async () => {
    const params = queryString.parse(location.search);

    if (params.id) {
      const columnName = "preferences_link";
      const { data, error } = await supabase
        .from("users_onboard")
        .select(`id, whatsapp_phone_no, ${columnName}`);

      if (error) {
        console.error("Error fetching data from Supabase:", error);
      } else if (data && data.length > 0) {
        const row = data.find(
          (row) => row[columnName] && row[columnName][params.id]
        );

        if (row) {
          getUserByPhone(row[columnName][params.id]).then((res) => {
            setUser(res);
          });
        } else {
          console.error("Key not found in any row's JSON object");
        }
      }
    }
  };

  const handleBuy = () => {
    navigate("/stripe");
  };

  useEffect(() => {
    verifyUser();
  }, [location, setUser]);

  return (
    user && (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: "100vh",
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: 2, sm: 4 },
          }}
        >
          <Box sx={{ maxWidth: 1200, width: "100%" }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                textAlign: "center",
                mb: 4,
              }}
            >
              Welcome, {user.name.split(" ")[0]}!
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 500,
                color: "text.primary",
                textAlign: "center",
                mb: 6,
              }}
            >
              Elevate your skills with our curated courses
            </Typography>
            <Grid container spacing={4}>
              {courses.map((course) => (
                <Grid item xs={12} sm={6} md={4} key={course.id}>
                  <StyledCard>
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <CourseIcon>{course.icon}</CourseIcon>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {course.title}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="secondary.main"
                        sx={{ fontWeight: "bold", marginTop: "auto" }}
                      >
                        ${course.price.toFixed(2)}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{ justifyContent: "center", paddingBottom: 3 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBuy}
                        sx={{
                          borderRadius: "25px",
                          padding: "10px 30px",
                          fontWeight: "bold",
                          textTransform: "none",
                          fontSize: "1rem",
                        }}
                      >
                        Enroll Now
                      </Button>
                    </CardActions>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    )
  );
};

export default CourseList;
