import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import StreakCalendar from "../components/StreakCalendar";
import AttentionScoreGrid from "../components/AttentionScoreGrid";
import { useParams } from "react-router-dom";
import axios from "axios";

const ShareableView = () => {
  const { shareId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchSharedData = async () => {
      try {
        const response = await axios.get(
          `https://backend.ekrasworks.com/share/${shareId}`
        );

        // Handle expired links
        if (response.status === 410) {
          setError("This share link has expired");
          setLoading(false);
          return;
        }

        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching shared data:", error);
        const errorMessage =
          error.response?.status === 410
            ? "This share link has expired"
            : "Failed to load shared progress";
        setError(errorMessage);
        setLoading(false);
      }
    };

    if (shareId) {
      fetchSharedData();
    }
  }, [shareId]);

  console.log("data: ", data);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography>No data found for this share link</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", p: 4 }}>
      <Paper elevation={0} sx={{ p: 4, borderRadius: 3 }}>
        <Typography variant="h4" align="center" gutterBottom>
          {`${data.userName.split(" ")[0]}'s Learning Progress`}
        </Typography>

        {/* Streak Section */}
        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" fontWeight={700} color="primary">
              {data.streakData.currentStreak}
            </Typography>
            <Typography variant="h6" sx={{ ml: 1 }}>
              days 🔥
            </Typography>
          </Box>
          <StreakCalendar
            completedDates={data.streakData.completedDates}
            startDate={data.streakData.startDate}
          />
        </Box>

        {/* Attention Grid */}
        <AttentionScoreGrid
          attentionScores={data.attentionData}
          startDate={data.streakData.startDate}
        />
      </Paper>
    </Box>
  );
};

export default ShareableView;
