import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import StreakCalendar from "../components/StreakCalendar";
import AttentionScoreGrid from "../components/AttentionScoreGrid";
import axios from "axios";

const ShareableViewInterim = ({ userId, challengeId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const response = await axios.get(
          `https://backend.ekrasworks.com/progress/${userId}/${challengeId}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching progress data:", error);
        setError("Failed to load progress data");
        setLoading(false);
      }
    };

    if (userId && challengeId) {
      fetchProgressData();
    }
  }, [userId, challengeId]);

  const loadingOrErrorBox = (content) => (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 4,
      }}
    >
      {content}
    </Box>
  );

  if (loading) return loadingOrErrorBox(<CircularProgress />);
  if (error)
    return loadingOrErrorBox(<Typography color="error">{error}</Typography>);
  if (!data)
    return loadingOrErrorBox(<Typography>No progress data found</Typography>);

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          p: { xs: 1.5, md: 4 },
          borderRadius: 3,
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2.25rem" },
            px: 1,
          }}
        >
          {`${data.userName.split(" ")[0]}'s Learning Progress`}
        </Typography>

        <Box
          sx={{
            width: "100%",
            mb: { xs: 2, md: 4 },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 1, md: 2 },
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              fontWeight={700}
              color="primary"
              sx={{ fontSize: { xs: "1.75rem", sm: "2.25rem", md: "3rem" } }}
            >
              {data.streakData.currentStreak}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem" },
              }}
            >
              days 🔥
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              boxSizing: "border-box",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <StreakCalendar
              completedDates={data.streakData.completedDates}
              startDate={data.streakData.startDate}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            boxSizing: "border-box",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <AttentionScoreGrid
            attentionScores={data.attentionData}
            startDate={data.streakData.startDate}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ShareableViewInterim;
