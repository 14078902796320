// Trainman.js
import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Avatar,
  Divider,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";

const ChatContainer = styled(Box)({
  display: "flex",
  height: "100vh",
});

const ChatList = styled(Box)({
  width: "300px",
  borderRight: "1px solid #e0e0e0",
});

const ChatWindow = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const MessageArea = styled(Box)({
  flex: 1,
  padding: "20px",
  overflowY: "auto",
  backgroundColor: "#f5f5f5",
});

const InputArea = styled(Box)({
  padding: "20px",
  borderTop: "1px solid #e0e0e0",
  backgroundColor: "#fff",
  display: "flex",
  gap: "10px",
});

const Message = styled(Paper)(({ isUser }) => ({
  padding: "10px 15px",
  maxWidth: "70%",
  marginBottom: "10px",
  backgroundColor: isUser ? "#1976d2" : "#fff",
  color: isUser ? "#fff" : "#000",
  alignSelf: isUser ? "flex-end" : "flex-start",
}));

const Trainman = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
    { id: 1, text: "Hello, I need help!", isUser: true },
    {
      id: 2,
      text: "Bob says: I can assist you with that. What do you need?",
      isUser: false,
    },
    { id: 3, text: "Can you tell me my order status?", isUser: true },
    { id: 4, text: "Bob says: Your order is being processed.", isUser: false },
  ]);

  const [chats] = useState([
    {
      id: 1,
      name: "Chat with Bob",
      lastMessage: "Your order is being processed",
    },
    { id: 2, name: "Chat with Alice", lastMessage: "Thank you for your help" },
    { id: 3, name: "Chat with Support", lastMessage: "How can I assist you?" },
  ]);

  const handleSend = () => {
    if (message.trim()) {
      setMessages([
        ...messages,
        { id: messages.length + 1, text: message, isUser: true },
      ]);
      setMessage("");

      // Simulate bot response
      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            text: "Bob says: Thanks for your message. I'll help you with that.",
            isUser: false,
          },
        ]);
      }, 1000);
    }
  };

  return (
    <ChatContainer>
      <ChatList>
        <Typography variant="h6" sx={{ p: 2 }}>
          Conversations
        </Typography>
        <Divider />
        <List>
          {chats.map((chat) => (
            <ListItem key={chat.id} button>
              <Avatar sx={{ mr: 2 }}>{chat.name[8]}</Avatar>
              <ListItemText primary={chat.name} secondary={chat.lastMessage} />
            </ListItem>
          ))}
        </List>
      </ChatList>

      <ChatWindow>
        <Typography variant="h6" sx={{ p: 2, backgroundColor: "#fff" }}>
          Chat with Bob
        </Typography>
        <MessageArea>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {messages.map((msg) => (
              <Message key={msg.id} isUser={msg.isUser}>
                <Typography>{msg.text}</Typography>
              </Message>
            ))}
          </Box>
        </MessageArea>

        <InputArea>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
          />
          <IconButton color="primary" onClick={handleSend}>
            <SendIcon />
          </IconButton>
        </InputArea>
      </ChatWindow>
    </ChatContainer>
  );
};

export default Trainman;
